@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Medium.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Demi.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Bold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "Maison Neue", "Source Sans Pro", sans-serif;
	box-sizing: border-box;
  color: #050526;
  margin: 0;
}

::-moz-selection { background-color: #30304f; color: #fff; }
::selection { background-color: #30304f; color: #fff; }

.inline-block {
  display: inline-block;
}

.center {
  text-align: center;
}

.header {
	position: relative;
  padding: 15vh 0;
  color: rgb(120, 104, 87);
  text-align: center;
  background: #eefbff;
}

.header .image-wrapper {
  width: 70vh;
  height: 70vh;
  margin: 0 auto;
}

.header img {
  position: relative;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0px 30px 100px -40px #37707d;
}

.header h1,
.header h2 {
  position: absolute;
  margin: 0;
  bottom: 40px;
  left: 50px;
  z-index: 1;
}

.header h2 {
  bottom: 95px;
  font-size: 1.5em;
  letter-spacing: -0.5px;
  color: #69d3ff;
  font-weight: 600;
}

.header h1 {
  font-size: 3em;
  letter-spacing: -1px;
  color: #050526;
  transform: translateX(-3px);
}

article {
  max-width: 700px;
  margin: 50px auto;
}

article .description {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.6;
  margin: 50px 0px;
  opacity: 0.7;
}

article h2 {
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 40px;
}

/* font-family: 'Inconsolata', monospace; */
article section {
  font-family: 'Merriweather', serif;
  font-size: 1.1em;
  line-height: 1.7;
  font-weight: 300;
}

article section .figure {
  margin: 20px 0px;
	width: 100%;
  max-width: 450px;
}

article section .figure .figure--text {
  font-family: "Maison Neue";
  font-size: 0.8em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.3;
  color: #050526a0;
}

article section .figure .figure--text b {
  color: #050526;
}

article section img {
  width: 100%;
}

@media (max-width: 800px) {
	article {
		margin: 50px;
	}

	.header .image-wrapper {
	  width: 35vh;
	  height: 35vh;
	}

	.header h1 {
		font-size: 1.5em;
		bottom: 20px;
		left: 50px;
	}

	.header h2 {
		font-size: 1em;
		bottom: 50px;
		left: 50px;
	}
}

@media (max-width: 500px) {
	article {
		margin: 50px 20px;
	}

	.header h1,
	.header h2 {
		left: 20px;
	}
}
